export default ($axios, store) => ({
  default: {
    ipinfo: () => $axios.$get('https://ipinfo.io/json?token=36cb150c4e378f', { withCredentials: false }),
    city: () => $axios.get('region/city'),
    tags: () => $axios.$get('tag'),
    categories: () => $axios.$get('category'),
    countries: () => $axios.$get('countries'),
    payments: () => $axios.$get('payment'),
    specifications: () => $axios.$get('cabinet/specification/'),
    homeProducts: () => $axios.$get('product/first'),
    products: (params = {}) => $axios.$post('products', params),
    storeProducts: storeId => $axios.post(`store/${storeId}/products`),
    offer: offer => $axios.$get(`offer/${offer}`),
    getProduct: (offerProduct, data) => $axios.$post(`offer/${offerProduct}/filter`, data),
    getProductCT: (offerProduct, data, cancelToken) => $axios.$post(`offer/${offerProduct}/filter`, data, { cancelToken }),
    getPrice: (id, data, type = 'op') => $axios.$post(`price/${type}/${id}/calculate`, data),
    validateTin: data => $axios.$post('pim/vat', data),
    getShippingInfo: (offerProduct, data) => $axios.$post(`shipping/op/${offerProduct}`, data),
    search: keyword => $axios.$get(`search/hints/${keyword}`),
    b2bEmail: data => $axios.$post('pim/b2b', data),
    getHome: () => $axios.$get('landing/home'),
    getCategory: data => $axios.$post('landing/page', data)
  },
  auth: {
    login: data => $axios.post('login', data),
    refreshToken: refreshToken => $axios.$post('token/refresh', { refreshToken }),
    register: (isSeller, data) => $axios.post(isSeller ? 'registration/seller' : 'registration/client', data),
    emailConfirm: token => $axios.$get(`confirm/${token}`),
    social: {
      facebook: data => $axios.post('external/facebook', data),
      google: data => $axios.post('external/google', data)
    },
    session: () => $axios.$get('session')
  },
  sms: {
    generate: () => $axios.$post('sms/code'),
    validate: code => $axios.$post(`sms/${code}/validate`)
  },
  passwordReset: {
    email: data => $axios.post('password/reset/email', data),
    check: data => $axios.post('password/reset/check', data),
    reset: data => $axios.post('password/reset', data)
  },
  messenger: {
    client: {
      get: (status, data) => $axios.$post(`ticket/status/${status}/paginate`, data),
      latest: (status, data) => $axios.$post(`/ticket/latest/${status}`, data),
      newTicket: (type, id, data) => $axios.$post(`ticket/${type}/${id}`, data)
    },
    seller: {
      get: (status, data) => $axios.$post(`cabinet/ticket/${status}/paginate`, data),
      latest: (status, data) => $axios.$post(`cabinet/ticket/latest/${status}`, data),
      newTicket: (type, id, data) => $axios.$post(`cabinet/ticket/${type}/${id}`, data),
      assign: data => $axios.$post('cabinet/store/ticket/assign', data)
    },
    detail: (ticket, cancelToken) => $axios.$get(`ticket/${ticket}/detail`, { cancelToken }),
    getMessages: ticket => $axios.$get(`ticket/${ticket}`),
    add: (ticket, data) => $axios.$post(`ticket/${ticket}`, data),
    seen: data => $axios.$put('ticket/seen', data),
    getNewMessages: (ticket, message) => $axios.$get(`ticket/${ticket}/message/${message}`),
    changeStatus: data => $axios.$put('cabinet/ticket/status', data),
    sendFiles: data => $axios.$post('attachment/add', data)
  },
  cart: {
    get: () => $axios.$get('cart'),
    add: (productId, data) => $axios.$post(`cart/${productId}`, data),
    getPrint: (type, id) => $axios.$get(`cart/${type}/${id}/print`),
    print: (type, id, data) => $axios.$post(`cart/${type}/${id}/print`, data),
    deleteProduct: orderOPId => $axios.$delete(`cart/${orderOPId}`),
    deleteAll: () => $axios.$delete('cart'),
    update: (orderOfferProductId, data) => $axios.$put(`cart/${orderOfferProductId}/quantity`, data),
    updateByCountry: countryId => $axios.$post(`cart/total/${countryId}`),
    saveShipping: data => $axios.$post('cart/shipping/save', data),
    checkout: () => $axios.$get('cart/summary'),
    changeSize: (orderOfferProductId, data) => $axios.$put(`cart/${orderOfferProductId}/size`, data),
    searchCouponCode: note => $axios.$put('cart/search/code', { note }),
    clientNote: (orderOfferProduct, clientNote) => $axios.$put(`cart/${orderOfferProduct}/note`, { clientNote })
  },
  payment: {
    paypalConfirm: token => $axios.$post('paypal/confirm', { token }),
    paypalCancel: data => $axios.$post('paypal/cancel', data)
  },
  comment: {
    get: (filter, page, limit) => $axios.$post(`product/comment/${filter}`, { page, limit }),
    post: (type, id, data) => $axios.$post(`comment/${type}/${id}`, data),
    voteReaction: (type, id) => $axios.$post(`/vote/comment/${id}/reaction/${type}`),
    offer: (offer, data) => $axios.$post(`offer/${offer}/rating`, data),
    photo: offer => $axios.$get(`offer/gallery/${offer}`),
    home: data => $axios.$post('landing/home/rating', data)
  },
  order: {
    get: orderId => $axios.$get(`order/${orderId}`),
    getWithToken: (orderId, token) => $axios.$post(`order/${orderId}/${token}`),
    getGroup: orderId => $axios.$get(`order/group/${orderId}`),
    getAll: (page, limit, status = 'all') => $axios.$post(`orders/paginate/${status}`, { page, limit }),
    add: data => $axios.$post('order/finish', data),
    buyAgain: orderId => $axios.$post(`order/${orderId}/copy`),
    payAgain: (orderId, data) => $axios.$post(`order/${orderId}/pay/again`, data),
    payExtraMoney: (orderId, data) => $axios.$post(`order/${orderId}/pay/extra/money`, data),
    getInvoice: orderInvoice => $axios.$post(`order/${orderInvoice}`)
  },
  cms: {
    list: data => $axios.$post('cms/post/list', data),
    url: data => $axios.$post('cms/url', data)
  },
  groupDeal: {
    create: (offerProduct, groupDeal) => $axios.$post(`cart/${offerProduct}/deal/${groupDeal}`),
    join: (deal, offerProduct, data) => $axios.$post(`cart/deal/${deal}/${offerProduct}`, data),
    get: identify => $axios.$get(`cart/deal/identify/${identify}`),
    updateNote: deal => $axios.$put(`cart/deal/${deal}/note`),
    getProduct: (groupDeal, offerProduct, data, cancelToken) => $axios.$post(`cart/deal/group/${groupDeal}/filter/${offerProduct}`, data, { cancelToken }),
    note: (deal, data) => $axios.$put(`cart/deal/${deal}/note`, data)
  },
  client: {
    get: () => $axios.$get('user'),
    put: data => $axios.$put('user', data),
    changePassword: data => $axios.$post('password/change', data),
    tradecredit: {
      info: data => $axios.$post('tradecredit/info', data),
      transactions: data => $axios.$post('tradecredit/transactions', data)
    }
  },
  // --------------------- Sprzedawca
  admin: {
    dashboard: data => $axios.$post('cabinet/order/dashboard', data),
    dashboardAddons: data => $axios.$post('cabinet/order/dashboard/addons', data),
    addImage: data => $axios.$post('image', data),
    image: (type, id, data) => $axios.$post(`cabinet/image/${type}/${id}`, data),
    pimCountries: () => $axios.$get('/cabinet/pim/countries'),
    filter: {
      get: () => $axios.$get('cabinet/filters'),
      option: (filter, data) => $axios.$post(`cabinet/filter/${filter}/option`, data),
      addFilter: data => $axios.$post('/cabinet/filter', data),
      updateFilter: (filter, data) => $axios.$put(`/cabinet/filter/${filter}`, data),
      sortFilter: (option, data) => $axios.$put(`cabinet/filter/option/${option}/sort`, data)
    },
    order: {
      get: id => $axios.$get(`cabinet/order/${id}`),
      getAll: (data, status = 'all') => $axios.$post(`cabinet/orders/paginate/${status}`, data),
      note: (id, data) => $axios.$post(`cabinet/order/${id}/note`, data),
      noteSds: (id, data) => $axios.$post(`cabinet/order/${id}/sds/note`, data),
      payment: (id, status, data = {}) => $axios.$put(`cabinet/order/${id}/payment/${status}`, data),
      shipping: (id, status) => $axios.$put(`cabinet/order/${id}/shipping/${status}`),
      waybill: (order, data) => $axios.$post(`cabinet/waybill/order/${order}`, data),
      deleteWaybill: waybill => $axios.$delete(`cabinet/waybill/${waybill}`),
      getQuantity: () => $axios.$get('cabinet/orders/quantity'),
      export: data => $axios.$post('cabinet/order/export', data),
      exportList: data => $axios.$post('cabinet/order/export/list', data),
      sendAgainEmail: (orderId, data) => $axios.$post(`cabinet/email/send/again/order/${orderId}`, data),
      editShippingAddress: (order, data) => $axios.$put(`cabinet/order/${order}/shipping/address/edit`, data),
      editEmail: (order, data) => $axios.$put(`cabinet/order/${order}/email`, data),
      addOrder: data => $axios.$post('/cabinet/order/add', data),
      uppdatePrice: data => $axios.$post('/cabinet/order/add/update/price', data),
      exports: {
        optima: data => $axios.$post('cabinet/order/export/optima', data),
        accountancy: data => $axios.$post('cabinet/order/export/accountancy', data),
        intrastat: data => $axios.$post('cabinet/order/export/intrastat', data),
        gus: data => $axios.$post('cabinet/order/export/gus', data),
        correctionOptima: data => $axios.$post('cabinet/order/export/optima/correction', data),
        products: data => $axios.$post('cabinet/order/export/list/type', data),
        saleByCategory: data => $axios.$post('cabinet/order/export/list/category', data),
        paymentMethods: data => $axios.$post('cabinet/order/export/payment/method  ', data)
      },
      importWaybills: data => $axios.$post('cabinet/waybill/import', data),
      changePaymentMethod: (id, data) => $axios.$put(`cabinet/order/${id}/payment_method`, data),
      email: {
        invoice: data => $axios.$post('cabinet/order/mail/invoice', { ids: data }),
        waybill: data => $axios.$post('cabinet/order/mail/waybill', { ids: data })
      },
      addressHistory: addressHistory => $axios.$get(`cabinet/address/history/${addressHistory}/order`),
      getStatuses: id => $axios.$get(`cabinet/order/${id}/statuses`),
      moneyBack: (order, data) => $axios.$post(`cabinet/correction/order/${order}/return`, data),
      regulationPayment: (id, type, data) => $axios.$put(`cabinet/order/${id}/regulation/${type}/status`, data),
      paybackList: data => $axios.$post('cabinet/order/payback/list', data),
      getInvoice: id => $axios.$get(`cabinet/order/pdf/${id}`),
      production: order => $axios.$put(`cabinet/order/${order}/go/production`)
    },
    spreadsheet: {
      generate: data => $axios.$post('cabinet/spreadsheet/generate', data),
      upload: formData => $axios.$post('cabinet/spreadsheet/upload', formData),
      getReport: id => $axios.$get(`cabinet/spreadsheet/report/${id}`),
      getReports: (type, data) => $axios.$post(`cabinet/spreadsheet/reports/${type}`, data),
      remove: spreadsheet => $axios.$delete(`cabinet/spreadsheet/${spreadsheet}`),
      cancel: spreadsheet => $axios.$put(`cabinet/spreadsheet/${spreadsheet}/cancel`),
      getDodatki: () => $axios.$get('cabinet/additional/option'),
      postDodatki: data => $axios.$post('cabinet/additional/option/add', data)
    },
    address: {
      post: (type, typeId, data) => $axios.$post(`cabinet/address/${type}/${typeId}`, data),
      put: (address, data) => $axios.$put(`cabinet/address/${address}`, data),
      delete: address => $axios.$delete(`cabinet/address/${address}`),
      main: address => $axios.$put(`cabinet/address/${address}/main`)
    },
    description: {
      post: (type, id, data) => $axios.$post(`cabinet/description/${type}/${id}`, data),
      put: (id, data) => $axios.$put(`cabinet/description/${id}`, data)
    },
    payment: {
      get: () => $axios.$get('cabinet/payment/payout'),
      payout: data => $axios.$post('cabinet/payment/payout', data)
    },
    invoice: {
      get: () => $axios.$get('cabinet/invoice'),
      pay: invoice => $axios.$post(`cabinet/invoice/${invoice}/pay`)
    },
    orderInvoice: {
      get: (order, type) => $axios.$get(`cabinet/order/${order}/invoice/edit/${type}`),
      update: (order, data) => $axios.$post(`cabinet/order/${order}/invoice`, data),
      generate: (order, data) => $axios.$post(`cabinet/order/${order}/generate`, data),
      saveProforma: (order, data) => $axios.$post(`cabinet/correction/order/${order}/proforma/save`, data)
    },
    correction: {
      generate: (order, data) => $axios.$post(`cabinet/correction/order/${order}/create`, data)
    },
    shipping: {
      get: () => $axios.$get('cabinet/store/shipping'),
      getPaginate: (page, limit) => $axios.$post('cabinet/store/shipping/paginate', { page, limit }),
      getType: () => $axios.$get('shipping/type'),
      post: data => $axios.$post('cabinet/shipping', data),
      duplicate: id => $axios.$post(`cabinet/shipping/clone/${id}`),
      put: (shipping, type, id) => $axios.$put(`/cabinet/shipping/${shipping}/${type}/${id}`),
      delete: shipping => $axios.$delete(`cabinet/shipping/${shipping}`),
      getCompanies: () => $axios.$get('cabinet/shipping/company')
    },
    offer: {
      productsList: () => $axios.$post('cabinet/offers/list'),
      productsListPaginate: data => $axios.$post('cabinet/offers/list/paginate', data),
      get: offer => $axios.$get(`cabinet/offer/${offer}`),
      getOffers: (page, limit) => $axios.$post('cabinet/offer/paginate', { page, limit }),
      getDraft: (offer, newDraft) => $axios.$get(`cabinet/offer/${offer}/edit/${newDraft}`),
      saveOffer: offer => $axios.$post(`cabinet/offer/${offer}/apply`),
      hasEdited: offer => $axios.$get(`cabinet/offer/${offer}/edited`),
      activeOffer: (offer, active) => $axios.$put(`/cabinet/offer/${offer}/active/${active}`),
      create: () => $axios.$post('cabinet/offer/'),
      addition: (offerProduct, data) => $axios.$post(`cabinet/op/${offerProduct}/addons`, data),
      valid: offer => $axios.$get(`cabinet/offer/${offer}/valid`),
      filterOrder: {
        get: offer => $axios.$get(`cabinet/offer/${offer}/filter`),
        save: (offer, data) => $axios.$post(`cabinet/offer/${offer}/filter`, data)
      },
      product: {
        op: {
          get: op => $axios.$get(`cabinet/op/${op}`),
          new: offer => $axios.$post(`cabinet/offer/${offer}/op`),
          add: (offer, data) => $axios.$post(`cabinet/offer/${offer}/ops`, data),
          remove: offerProduct => $axios.$delete(`cabinet/op/${offerProduct}`)
        },
        ap: {
          get: ap => $axios.$get(`cabinet/ap/${ap}`),
          add: (offer, data) => $axios.$post(`cabinet/offer/${offer}/ap`, data),
          remove: addonProduct => $axios.$delete(`cabinet/ap/${addonProduct}`)
        }
      }
    },
    product: {
      getProductsPaginate: data => $axios.$post('cabinet/products/paginate', data),
      getProductsSearch: data => $axios.$post('cabinet/products/list', data),
      getProducts: () => $axios.$get('cabinet/products'),
      get: id => $axios.$get(`cabinet/product/${id}`),
      post: data => $axios.$post('cabinet/product', data),
      duplicate: product => $axios.$post(`cabinet/product/${product}/duplicate`),
      tags: (type, id, data) => $axios.$post(`cabinet/tag/${type}/${id}`, data),
      category: (type, id, data) => $axios.$post(`cabinet/category/${type}/${id}`, data),
      stock: (type, id, data) => $axios.$put(`cabinet/stock/${type}/${id}`, data),
      size: (size, type, id, data) => $axios.$post(`cabinet/size/${size}/${type}/${id}`, data),
      minimalQuantity: (type, id, data) => $axios.$post(`cabinet/op/${type}/${id}`, data),
      filter: (type, id, data) => $axios.$post(`cabinet/option/${type}/${id}`, { filters: data }),
      specification: (type, id, data) => $axios.$post(`cabinet/specification/${type}/${id}`, data),
      bulletpoint: (type, id, data) => $axios.$post(`cabinet/bulletpoint/${type}/${id}`, data),
      faq: (type, id, data) => $axios.$post(`cabinet/faq/${type}/${id}`, data),
      inherit: offerProduct => $axios.$put(`cabinet/product/inherit/${offerProduct}`),
      print: (type, id, data) => $axios.$put(`cabinet/pim/${type}/${id}/print`, data),
      delete: data => $axios.delete('cabinet/product', { data }),
      identifier: (type, id, data) => $axios.$post(`cabinet/pim/${type}/${id}/extended/identifier`, data),
      deliveryTime: (type, id, data) => $axios.$post(`cabinet/pim/${type}/${id}/delivery`, data),
      extendedDescriptions: (type, id, data) => $axios.$post(`cabinet/extended/description/${type}/${id}`, data),
      extendedSpecifications: (type, id, data) => $axios.$post(`cabinet/extended/specification/${type}/${id}`, data),
      priceGroup: (type, id, data) => $axios.$post(`/cabinet/price/${type}/${id}/all`, data)
    },
    descriptionTemplates: {
      list: data => $axios.$post('cabinet/extended/description/list', data),
      remove: description => $axios.delete(`cabinet/extended/description/${description}/remove`),
      get: description => $axios.$get(`cabinet/extended/description/${description}/detail`),
      update: data => $axios.$post('cabinet/extended/description/create', data)
    },
    discount: {
      post: (type, id, data) => $axios.$post(`cabinet/discount/${type}/${id}`, data),
      put: (discount, data) => $axios.$put(`cabinet/discount/${discount}`, data),
      delete: price => $axios.delete(`cabinet/discount/${price}`)
    },
    price: {
      post: (type, id, data) => $axios.$post(`cabinet/price/${type}/${id}`, data),
      put: (price, data) => $axios.$put(`cabinet/price/${price}`, data),
      delete: price => $axios.delete(`cabinet/price/${price}`),
      convert: data => $axios.$post('cabinet/price/convert', data)
    },
    pricesOnQuantity: {
      export: (offer, data) => $axios.$post(`cabinet/price/offer/${offer}/export`, data),
      import: data => $axios.$post('cabinet/price/offer/quantity/import', data)
    },
    store: {
      get: () => $axios.$get('cabinet/store'),
      getVat: () => $axios.$get('cabinet/store/vat'),
      put: data => $axios.$put('cabinet/store', data),
      vat: data => $axios.$post('cabinet/store/vat', data),
      questions: {
        edit: () => $axios.$get('cabinet/store/question'),
        save: data => $axios.$post('cabinet/store/question', data),
        list: () => $axios.$get('pim/question/list')
      }
    },
    size: {
      getAll: () => $axios.$get('cabinet/size/group'),
      getPaginate: (page, limit) => $axios.$post('cabinet/size/group/paginate', { page, limit }),
      duplicate: group => $axios.$post(`cabinet/group/${group}/duplicate`),
      get: id => $axios.$get(`cabinet/size/group/${id}`),
      delete: id => $axios.$delete(`cabinet/size/group/${id}`),
      post: data => $axios.$post('cabinet/size', data),
      import: data => $axios.$post('cabinet/group/import', data),
      export: data => $axios.$post('cabinet/group/export', data)
    },
    coupons: {
      get: () => $axios.$get('cabinet/group/code'),
      getById: id => $axios.$get(`cabinet/group/code/${id}`),
      addGroup: data => $axios.$post('cabinet/group/code', data),
      assignGroup: (type, id, data) => $axios.$post(`cabinet/group/code/${type}/${id}`, data)
    },
    clients: {
      list: data => $axios.$post('/cabinet/client/paginate', data),
      detail: client => $axios.$get(`cabinet/client/${client}/show`),
      changeEmail: (client, data) => $axios.$put(`cabinet/client/${client}/email`, data),
      numberOfClients: () => $axios.$get('/cabinet/client/quantity'),
      clientStatus: (client, status) => $axios.$post(`/cabinet/client/${client}/group/status/${status}`)
    },
    groupDeal: {
      get: id => $axios.$get(`/cabinet/group/deal/${id}`),
      list: data => $axios.$post('/cabinet/group/deal/paginate', data),
      search: data => $axios.$post('/cabinet/group/deal/list', data),
      add: data => $axios.$post('/cabinet/group/deal', data),
      assing: (group, data) => $axios.$post(`cabinet/group/${group}/assing/op`, data),
      inactive: offer => $axios.$post(`cabinet/group/assing/${offer}/inactive`),
      remove: groupDeal => $axios.$delete(`cabinet/group/deal/${groupDeal}`),
      deals: (id, data, type = 'groupDeal') => $axios.$post(`cabinet/group/deal/${type}/${id}`, data)
    },
    clientGroup: {
      listType: data => $axios.$post('cabinet/client/group/list', data),
      mainListType: () => $axios.$get('cabinet/client/group/client/type/list'),
      clientPrice: (client, clientPriceGroup) => $axios.$post(`/cabinet/client/${client}/group/${clientPriceGroup}`),
      priceGroup: {
        update: data => $axios.$post('cabinet/client/group/create', data),
        get: id => $axios.$get(`cabinet/client/group/${id}/detail`)
      }
    },
    cms: {
      pageList: data => $axios.$post('cabinet/cms/page/list', data),
      postList: data => $axios.$post('cabinet/cms/post/list', data),
      update: data => $axios.$post('/cabinet/cms/update', data),
      get: group => $axios.$get(`/cabinet/cms/group/${group}`),
      checkSlug: data => $axios.$post('cabinet/cms/slug', data)
    },
    marketing: {
      list: data => $axios.$post('cabinet/marketing/list', data),
      create: data => $axios.$post('cabinet/marketing/', data),
      edit: (marketing, data) => $axios.$post(`cabinet/marketing/${marketing}/edit`, data),
      active: (marketing, active) => $axios.$post(`cabinet/marketing/${marketing}/active/${active}`),
      detail: marketing => $axios.$get(`/cabinet/marketing/${marketing}/detail`)

    },
    employees: {
      list: data => $axios.$post('cabinet/store/users'),
      create: data => $axios.$post('cabinet/store/user', data),
      getEmployee: id => $axios.$get(`/cabinet/store/user/${id}`),
      edit: (id, data) => $axios.$put(`/cabinet/store/user/${id}`, data)
    },
    tradecredit: {
      list: data => $axios.$post('cabinet/tradecredit/users', data),
      edit: data => $axios.$post('cabinet/tradecredit/update', data),
      payment: data => $axios.$post('cabinet/tradecredit/payment/update', data),
      info: data => $axios.$post('cabinet/tradecredit/info', data),
      transactions: data => $axios.$post('cabinet/tradecredit/transactions', data)
    },
    statistics: {
      show: data => $axios.$post('cabinet/messenger/dashboard', data)
    }
  }
})
